<template>
  <div class="popup" :style="positionStyle">
    <Tile>
      <slot />
    </Tile>
  </div>
</template>

<script>
export default {
  name: "Popup",

  components: {
    Tile: () => import("@/components/Tile"),
  },

  props: {
    position: {
      type: Array,
      default: null,
    },
  },

  computed: {
    positionStyle() {
      let style = "";

      for (const item of this.position) {
        style += `${item}: 2rem; `;
      }

      return style;
    },
  },
};
</script>

<style lang="scss">
@import "../styles/globals";
@import "../styles/colors";

.popup {
  position: fixed;
  min-width: 20rem;
  border-radius: 3px;
  overflow-y: auto;
  max-height: 500px;
  box-shadow: $box-shadow;
}

.popup__header {
  display: flex;
  border-bottom: 1px solid color(border, base);
  align-items: center;
  color: color(black, base);
}

.popup__amount {
  display: flex;
  border-radius: 50%;
  width: 1.5rem;
  flex-shrink: 0;
  font-weight: 700;
  font-size: 0.75rem;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  background-color: color(cta, primary);
  color: color(white, base);
}

.popup__list {
  display: flex;
  flex-direction: column;
}

.popup__list-item {
  display: flex;
  padding: 1rem 1.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid color(border, base);
  }
}
</style>
